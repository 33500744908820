// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-akutagawa-js": () => import("./../../../src/pages/akutagawa.js" /* webpackChunkName: "component---src-pages-akutagawa-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-gunzo-js": () => import("./../../../src/pages/gunzo.js" /* webpackChunkName: "component---src-pages-gunzo-js" */),
  "component---src-pages-honya-js": () => import("./../../../src/pages/honya.js" /* webpackChunkName: "component---src-pages-honya-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mishima-js": () => import("./../../../src/pages/mishima.js" /* webpackChunkName: "component---src-pages-mishima-js" */),
  "component---src-pages-naoki-js": () => import("./../../../src/pages/naoki.js" /* webpackChunkName: "component---src-pages-naoki-js" */),
  "component---src-pages-noma-js": () => import("./../../../src/pages/noma.js" /* webpackChunkName: "component---src-pages-noma-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-tanizaki-js": () => import("./../../../src/pages/tanizaki.js" /* webpackChunkName: "component---src-pages-tanizaki-js" */),
  "component---src-pages-yomiuri-js": () => import("./../../../src/pages/yomiuri.js" /* webpackChunkName: "component---src-pages-yomiuri-js" */),
  "component---src-pages-yoshikawa-js": () => import("./../../../src/pages/yoshikawa.js" /* webpackChunkName: "component---src-pages-yoshikawa-js" */),
  "component---src-templates-book-detail-for-related-book-js": () => import("./../../../src/templates/book_detail_for_related_book.js" /* webpackChunkName: "component---src-templates-book-detail-for-related-book-js" */),
  "component---src-templates-book-detail-js": () => import("./../../../src/templates/book_detail.js" /* webpackChunkName: "component---src-templates-book-detail-js" */)
}

